import React from 'react';
import { graphql } from 'gatsby';

import CustomLink from 'common/CustomLink';
import Card from 'components/Card';

import './ProductCategoryCards.scss';

const ProductCategoryCards = ({
  categoryCards,
  title,
  customLink,
}: PageContent.ProductCategoryCardsType) => {
  const { linkLabel, color, link, isLinkExternal } = customLink || {};

  return (
    <section
      className="product-category-cards"
      data-testid="product-category-cards"
      id="main-content"
    >
      <div className="product-category-cards__top">
        <h2 className="product-category-cards__title">{title}</h2>
        <CustomLink
          {...{
            linkLabel,
            color,
            link,
            isLinkExternal,
          }}
        />
      </div>
      <div className="product-category-cards__items">
        {categoryCards.map((card) => (
          <Card
            className="card--small"
            topCardContent={{ cardImage: card }}
            bottomCardContent={{ title: card.title }}
            key={card.title}
          >
            {card.description}
            <CustomLink
              linkLabel={card.customLink.linkLabel}
              color={card.customLink.color}
              link={card.customLink.link}
              isLinkExternal={card.customLink.isLinkExternal}
            />
          </Card>
        ))}
      </div>
    </section>
  );
};

export const ProductCategoryCardsType = graphql`
  fragment ProductCategoryCardsType on ProductCategoryCardsType {
    categoryCards {
      ...CardType
    }
    title
    customLink {
      ...CustomLinkType
    }
  }
`;

export default ProductCategoryCards;
