import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';

import CustomButton from 'common/CustomButton';

import './SubscribePopup.scss';

const appearanceTime = 10000;

const SubscribePopup = ({
  customButton,
  description,
  closeAriaLabel,
}: PageContent.SubscribePopupType) => {
  const [isVisible, setIsVisible] = useState(false);
  const handleClose = () => setIsVisible(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, appearanceTime);
  }, []);

  return isVisible ? (
    <section className="subscribe-popup" data-testid="subscribe-popup">
      <h3 className="subscribe-popup__description">{description}</h3>
      <CustomButton
        {...customButton}
        onClick={() => navigate(customButton?.buttonLink?.url!)}
        className="subscribe-popup__button"
      />
      <span
        role="button"
        className="subscribe-popup__close"
        onClick={handleClose!}
        onKeyDown={handleClose!}
        tabIndex={0}
        aria-label={closeAriaLabel}
      />
    </section>
  ) : null;
};

export const SubscribePopupType = graphql`
  fragment SubscribePopupType on SubscribePopupType {
    description
    customButton {
      ...CustomButtonType
    }
    closeAriaLabel
  }
`;

export default SubscribePopup;
