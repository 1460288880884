import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import Container from 'layout/Container';
import Layout from 'layout/Layout';
import ClaimText from 'common/ClaimText';
import IconList from 'common/IconList';
import Seo from 'common/Seo';
import StructuredPageSchema from 'common/StructuredPageSchema';
import ProductCategoryCards from 'components/ProductCategoryCards';
import SubscribePopup from 'components/SubscribePopup';
import { HP_CAROUSEL_SETTINGS } from 'utils/constants';
import runOnUserInteraction from 'utils/runOnUserInteraction';
import { schemaService } from 'utils/schemaService';

import './HomePage.scss';

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: {
      urls,
      seo,
      mainCarousel: { carouselItems, prevArrowLabel, nextArrowLabel },
      claimText,
      iconList,
      productCategoryCards,
      infoBanner,
      customText,
      videoBanner,
      subscribePopup,
    },
    siteSettings,
    brandSettings,
    allFooter,
  },
  pageContext: { lang },
}) => {
  const [shouldCarouselLoad, setShouldCarouselLoad] = useState(false);

  const Banner = loadable(() => import('components/Banner'));
  const CustomCarousel = loadable(() => import('common/CustomCarousel'));

  const schemaData = schemaService.extractSchemaData({
    seo,
    siteUrl: siteSettings.siteUrl,
    pageUrl: siteSettings.siteUrl,
    siteSettings,
    brandSettings,
    lang: siteSettings.lang,
    socialNetworks: allFooter?.nodes?.[0]?.socialMediaLinks,
  });

  useEffect(() => {
    runOnUserInteraction(() => setShouldCarouselLoad(true));
  }, []);

  return (
    <Layout headerTransparent>
      <Seo {...{ lang, urls, seo }} />
      <StructuredPageSchema additionalSchema="Organization" schemaData={schemaData} />
      <CustomCarousel
        settings={HP_CAROUSEL_SETTINGS}
        isWithCustomArrows
        prevArrowLabel={prevArrowLabel}
        nextArrowLabel={nextArrowLabel}
      >
        {carouselItems
          .slice(0, shouldCarouselLoad ? carouselItems.length : 1)
          .map((carouselItem) => (
            <Banner key={carouselItem.mainTitle.title} {...carouselItem} />
          ))}
      </CustomCarousel>
      <ClaimText {...{ ...claimText }} />
      <section className="background-pink">
        <Container>
          <IconList {...{ iconList }} />
        </Container>
      </section>
      <Container>
        <ProductCategoryCards {...productCategoryCards} />
      </Container>
      <section className="background-pink">
        <Container>
          <Banner {...infoBanner} />
        </Container>
      </section>
      <section className="background-white">
        <Container>
          <Banner {...videoBanner} />
        </Container>
      </section>
      <ClaimText {...{ ...customText }} />
      <SubscribePopup {...subscribePopup} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      mainCarousel {
        ...MainCarouselType
      }
      claimText {
        ...ClaimTextType
      }
      iconList {
        ...IconListType
      }
      productCategoryCards {
        ...ProductCategoryCardsType
      }
      infoBanner {
        ...BannerType
      }
      customText {
        ...ClaimTextType
      }
      videoBanner {
        ...BannerType
      }
      subscribePopup {
        ...SubscribePopupType
      }
    }
    siteSettings {
      siteUrl
      siteName
      lang
    }
    brandSettings {
      brandName
      brandLogo {
        ...UmbracoImage
      }
    }
    allFooter {
      nodes {
        socialMediaLinks {
          ...SocialMediaLinkType
        }
      }
    }
  }
`;

export default HomePage;
