import React from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import './ClaimText.scss';

const ClaimText = ({ text, withBackground }: PageContent.ClaimTextType) => {
  const claimTextClass = classNames('claim-text', {
    'claim-text--background': withBackground,
  });

  return (
    <DangerouslySetInnerHtml html={text} className={claimTextClass} data-testid="claim-text" />
  );
};

export const ClaimTextType = graphql`
  fragment ClaimTextType on ClaimTextType {
    text
    withBackground
  }
`;

export default ClaimText;
