import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import Image from 'common/Image';

import { CardProps } from './models';

import './Card.scss';

const Card: FC<CardProps> = ({ className, children, topCardContent, bottomCardContent }) => {
  const { cardImage, topCardClass } = topCardContent || {};
  const { bottomCardClass, title, titleClassname, titleAsLink, url } = bottomCardContent || {};

  const createTitle = () =>
    titleAsLink ? (
      <Link aria-label={title} to={url!}>
        {title}
      </Link>
    ) : (
      <h3>{title}</h3>
    );

  return (
    <div className={classnames('card', className)}>
      <div className={classnames('card__top', topCardClass)}>
        {cardImage ? <Image alt={cardImage.image.altText} imageData={cardImage.image} /> : null}
      </div>
      <div className={classnames('card__bottom', bottomCardClass)}>
        {title ? <div className={titleClassname}>{createTitle()}</div> : null}
        {children}
      </div>
    </div>
  );
};

export const CardType = graphql`
  fragment CardType on CardType {
    image {
      ...UmbracoImage
    }
    title
    description
    customLink {
      ...CustomLinkType
    }
  }
`;

export default Card;
